export default {
  greeting:
    "Ihre Fragen können Sie in das Chatfenster eingeben oder einen Vorschlag auswählen. <br> Bitte haben Sie Verständnis, dass ich mich noch im Aufbau befinde und mich mit jeder Frage weiterentwickle.",

  suggestions: [],
  noAnswerText:
    "Leider kann ich Ihre Frage nicht beantworten. Bitte versuchen Sie es mit einer anderen Formulierung",
  noAnswerSuggestions: [],
  botName: "Querkraft",
  botTitle: "",
  client: "https://analytics-api.institut.digital",

  endpoint: "/knowledgebases/cc2e78ab-9078-4b14-a32f-bad9c310ceb7/generateAnswer",
  endpointAuthKey: "955cd5f4-211c-4eee-832e-061983caf941",

  // confidence threshold in percent
  confidenceScore: 0,

  primaryColor: "#40AC57",
  secondaryColor: "",

  // e.g https://analytics-api.institut.digital/api/ext/XYZ
  analyticsEndpoint: "zrscAAHOWi9ova3DJsIEakTvOoW6EVtX",
  useAnalytics: false,
  logGreeting: false,
};
